$(document).ready(function() {

    var ua = navigator.userAgent,
        isMobileWebkit = /WebKit/.test(ua) && /Mobile/.test(ua);
    if (isMobileWebkit) {
        $('body').addClass('mobile');
    }

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    $('.burger').on('click', function(e) {
        e.preventDefault();
        $(this).next('.mmenu').toggleClass('dropdown');
    })

});

window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});
